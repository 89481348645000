// npm
import React, { memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Grid, useTheme } from '@mui/material'
import { Formik, Form, FormikHelpers } from 'formik'
import { object, string } from 'yup'

// components
import Button from '@atoms/buttons/Button'
import SmallDivider from '@atoms/dividers/SmallDivider'
import FormikTextField from '@molecules/formik/FormikTextField'

// context
import { useMessage } from '@context/MessageContext'

// helpers
import encodeForm from '@helpers/encodeForm'

interface FormValues {
  name: string
  email: string
  message: string
}

const EnquiryForm = () => {
  const theme = useTheme()
  const { dispatch } = useMessage()
  const data = useStaticQuery<Queries.ArtworkEnquiryFormQuery>(graphql`
    query ArtworkEnquiryForm {
      datoCmsEnquiryFormGlobal {
        ...enquiryForm
      }
    }
  `)
  const d = data.datoCmsEnquiryFormGlobal

  const initialValues: FormValues = {
    name: '',
    email: '',
    message: '',
  }

  const validationSchema = object().shape({
    name: string().required(d.nameRequiredValidationMessage),
    email: string()
      .email(d.emailFormatValidationMessage)
      .required(d.emailRequiredValidationMessage),
    message: string().required(d.messageRequiredValidationMessage),
  })
  const formName = 'Enquiry'

  const gtag_report_conversion = (url: string) => {
    if (typeof window !== 'undefined') {
      var callback = function () {
        if (typeof url != 'undefined') {
          window.location = url
        }
      }
      window.gtag('event', 'conversion', {
        send_to: 'AW-11177044910/Iu1dCP_nxaIYEK7X0NEp',
        event_callback: callback,
      })
      return false
    }
  }

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeForm({ 'form-name': formName, ...values }),
    })
      .then(() => {
        // @ts-ignore
        if (typeof window !== 'undefined') {
          window.gtag('event', 'website_enquiry')
          gtag_report_conversion(window.location.href)
        }
        dispatch({
          type: 'SET_MESSAGE',
          payload: {
            message: d.formSubmissionSuccessMessage,
            status: 'success',
          },
        })
        actions.resetForm()
      })
      .catch(() => {
        dispatch({
          type: 'SET_MESSAGE',
          payload: {
            message: d.formSubmissionErrorMessage,
            status: 'error',
          },
        })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <>
      <Box
        component="h2"
        typography="sectionHeading"
        mt={0}
        mb={2}
        textAlign="center"
      >
        {d.enquriryHeading}
      </Box>
      <SmallDivider sx={{ m: `0 auto ${theme.spacing(5)}` }} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: FormValues, actions: FormikHelpers<FormValues>) => {
          handleSubmit(values, actions)
        }}
      >
        <Form
          name={formName}
          data-netlify={true}
          netlify-honeypot="botField"
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <FormikTextField
                name="name"
                label="Name"
                fullWidth
                variant="outlined"
                fieldTheme="outlined-light"
                sx={{ m: 0 }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormikTextField
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="outlined"
                fieldTheme="outlined-light"
                sx={{ mb: 0, mt: { xs: 2, lg: 0 } }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                name="message"
                label="Message"
                multiline
                rows={3}
                fullWidth
                variant="outlined"
                fieldTheme="outlined-light"
                sx={{ mb: 0, mt: 2 }}
              />
            </Grid>
          </Grid>

          <Box mt={5} textAlign="center">
            <Button type="submit" sx={{ color: theme.palette.text.primary }}>
              {d.submitButtonLabel}
            </Button>
          </Box>
        </Form>
      </Formik>
    </>
  )
}

export default memo(EnquiryForm)
